

  nav{
    font-weight: 800;
    background: #111;

  }
  nav ul {
    list-style-type: none;
    text-decoration: none;
  

  }
  nav a {
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    color: whitesmoke;
    display: block;
    margin: auto;
    padding: 10px;
    background: #232323;
  }
  .signin {
    float: right !important;
    position: absolute;
    right: 33px;

    background: #000 !important;
    border: #000;
    margin: -2px;
    margin-top: 2px;

    border-color: green;
    background-color: yellowgreen !important;
    border-width: 1px;
    border-style: solid double solid double;

  }
  .signup {
    float: right !important;
    position: absolute;
    right: 107px;
    background: #0096FF !important;
    border: #000;
    margin: -2px;
    margin-top: 2px;
    
    border-color: blue !important;
    border-width: 1px;
    border-style: solid double solid double;


  }
  li .hamburger{
    margin-bottom: -2px;
  }
  
  nav li {

    margin-top: auto;
    margin-bottom: auto;
    background-color: #111;


  }
  ul{
    
  }
  nav a:hover{
    background: #333;
    color: cornsilk;
  }
  .active{
    border-color: pink;
margin: 1px;
    background-color: #434343 !important;
    border-width: 2px;
    border-style: solid double solid double;
    
  }
  .inactive{
    border-color:plum;
    border-width: 2px;
    border-style: solid double solid double;
  }


    @media screen and (max-width: 768px){
   ul{ padding-inline-start: 0px;}
   li .hamburger{
    display:block;
  }


  .hamburger{
    margin: 0px !important;
    padding: px !important;
    width: 100%;
    margin-right: 18px !important;
    right: 5px !important;
  }
  .brand{
    margin-top: -46px;
    right: 4px;
    border: 0;
    display: none;
    background-color: #212121 !important;
    padding-left: 4px;
  left: 9px !important;
    margin-left: 55px;
  }
  .hiddenR{
    display: block;
  }
  .signup{
    float: right !important;
    position: absolute;
    left: 3px;
    width: 45%;
    margin-top: 2px;
    margin-right: -33px !important;

    background: #0096FF !important;
    border: #000;
    border-color: #0096FF;
    background-color: #434343 !important;
    border-width: 1px;
    border-style: solid double solid double;

  }
  .signin{
    float: right !important;
    position: absolute;
    right: 3px;
    background: #000 !important;
    border: #000;
    margin-top: 2px;
    width: 45%;
    border-color: yellowgreen;
    background-color: #434343 !important;
    border-width: 1px;
    border-style: solid double solid double;
  }
  nav  {
    margin-right: -4px;
    padding-left: 3px;
    padding-right: 3px;

    margin-left: -4px;
    margin-bottom: 49px;
  }
  }
  @media screen and (min-width: 768px){
  
    nav ul {
      display: flex;
    }
    nav .mainMenuLinks{
      display: flex;
    }
    nav li .menuLinks{
      flex: 1 1 0;
    }
   
  
    
  }

  .hidden {  
    transition-timing-function: ease-out;
    transition-delay: 1s;
    display:none;
}

  .hamburger{
    margin: 0px;
    cursor: pointer;
    padding: 3px;
    background-color: #111;
  }

  
